import create from "zustand";
import { persist } from "zustand/middleware";
import { nanoid } from "nanoid";
import produce from "immer";

const useStore = create(
  persist(
    (set) => ({
      jobSite: [],
      addJobSite: () => {
        set((state) => ({
          jobSite: [
            ...state.jobSite,
            {
              id: nanoid(),
              name: `Job Site #${state.jobSite.length + 1}`,
              runs: [],
              siteDetails: {},
              runOpen: null,
              typeOpen: "round",
            },
          ],
        }));
      },
      setJobName: (jobIndex, name) => {
        set(
          produce((state) => {
            state.jobSite[jobIndex].name = name;
          })
        );
      },
      jobSiteOpen: null,
      setJobSiteOpen: (value) => {
        set(() => ({ jobSiteOpen: value }));
      },
      canvasView: true,
      setCanvasView: (value) => {
        set(() => ({ canvasView: value }));
      },
      setSiteDetails: (index, data) => {
        set(
          produce((state) => {
            state.jobSite[index].siteDetails = data;
          })
        );
      },
      addRun: (index) => {
        set(
          produce((state) => {
            state.jobSite[index].runs.push({
              id: nanoid(),
              name: `Run #${state.jobSite[index].runs.length + 1}`,
              canvas: {},
              items: [],
            });
          })
        );
      },
      setRunOpen: (jobIndex, runId) => {
        set(
          produce((state) => {
            state.jobSite[jobIndex].runOpen = runId;
          })
        );
      },
      setTypeOpen: (jobIndex, value) => {
        set(
          produce((state) => {
            state.jobSite[jobIndex].typeOpen = value;
          })
        );
      },
      addFitting: (fittingId, type, jobIndex, runIndex) => {
        set(
          produce((state) => {
            state.jobSite[jobIndex].runs[runIndex].items.push({
              id: nanoid(),
              fittingId: fittingId,
              type: type,
              measurements: {},
            });
          })
        );
      },
      setMeasurements: (jobIndex, runIndex, itemIndex, value) => {
        set(
          produce((state) => {
            state.jobSite[jobIndex].runs[runIndex].items[
              itemIndex
            ].measurements = value;
          })
        );
      },
      setCanvas: (fn) => set(produce(fn)),
    }),
    { name: "PrimeCommercialAppData" }
  )
);

export default useStore;

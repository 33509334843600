import React, { useState } from "react";

// Store
import useStore from "../Store/useStore";
import Icons from "../Icons";
import Popup from "./Popup";

const JobHeader = () => {
  const { addJobSite, setCanvasView, canvasView } = useStore((state) => ({
    addJobSite: state.addJobSite,
    canvasView: state.canvasView,
    setCanvasView: state.setCanvasView,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const startOver = () => {
    console.log("Start Over...");
  };

  return (
    <>
      <div className="bg-gray-200 p-4 flex justify-between fixed w-full z-50 border-b">
        <button className="btn-success" onClick={() => addJobSite()}>
          {Icons.plus} Job Site
        </button>

        <div className="gap-2 flex">
          <span className="self-center">Scrap Factor: 0%</span>
          <button className="btn-white" onClick={() => setIsOpen(true)}>
            {Icons.startOver} Start Over
          </button>
          <button
            className="btn-white"
            onClick={() => setCanvasView(!canvasView)}
          >
            {canvasView ? "List" : "Canvas"}
          </button>
        </div>
      </div>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="text-2xl mb-5">{Icons.warning} Warning</div>
        <div className="text-gray-500 mb-8">
          This will clear all your saved data. This action cannot be undone.
        </div>
        <div className="flex gap-2">
          <button className="btn-primary" onClick={() => startOver()}>
            Start Over
          </button>
          <button className="btn-secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Popup>
    </>
  );
};

export default JobHeader;

import React from "react";
import { nanoid } from "nanoid";
import RectangularFittings from "../data/rectangular";

import useStore from "../Store/useStore";

const RectangularFitting = ({ jobIndex, runIndex }) => {
  const { addFitting } = useStore((state) => ({
    addFitting: state.addFitting,
  }));
  return (
    <>
      {RectangularFittings.map((fitting) => {
        return (
          <div
            key={nanoid()}
            className="shadow p-2 cursor-pointer hover:bg-gray-100"
            onClick={() =>
              addFitting(fitting.id, "rectangular", jobIndex, runIndex)
            }
          >
            <img src={fitting.image} alt={fitting.name} width="90" />
          </div>
        );
      })}
    </>
  );
};

export default RectangularFitting;

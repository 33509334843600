import React from "react";

import useStore from "../Store/useStore";

const JobSites = () => {
  const { jobs, siteOpen, setJobSiteOpen } = useStore((state) => ({
    jobs: state.jobSite,
    siteOpen: state.jobSiteOpen,
    setJobSiteOpen: state.setJobSiteOpen,
  }));

  return (
    <>
      {jobs.map((job) => {
        return (
          <button
            key={job.id}
            className={`${
              siteOpen === job.id ? "btn-primary" : "btn-secondary"
            }`}
            onClick={() => setJobSiteOpen(job.id)}
          >
            {job.name}
          </button>
        );
      })}
    </>
  );
};

export default JobSites;

import JobHeader from "./components/JobHeader";
import JobList from "./components/JobList";

function App() {
  return (
    <>
      <div className=" flex flex-col">
        <JobHeader />
        <div className="p-4">
          <JobList />
        </div>
      </div>
    </>
  );
}

export default App;

import React from "react";

import useStore from "../Store/useStore";
import rectangularFittings from "../data/rectangular";
import roundFittings from "../data/round";
import _ from "lodash";

const ListView = ({ run }) => {
  const { canvasView } = useStore((state) => ({
    canvasView: state.canvasView,
  }));

  const filteredRunItemsArr = run.items.reduce((unique, o) => {
    if (
      !unique.some(
        (obj) =>
          obj.fittingId === o.fittingId &&
          _.isEqual(obj.measurements, o.measurements)
      )
    ) {
      unique.push(o);
    }
    return unique;
  }, []);

  const fittingQty = (fittingId, measurements) => {
    return _.filter(
      run.items,
      (item) =>
        item.fittingId === fittingId &&
        _.isEqual(item.measurements, measurements)
    ).length;
  };

  const tableHeading = () => {
    if (filteredRunItemsArr.length > 0) {
      return (
        <div className="flex flex-row justify-between">
          <div className="w-1/12 font-bold">Qty</div>
          <div className="w-2/5 font-bold">Description</div>
          <div className="w-2/5 font-bold">Measurements</div>
          <div className="w-1/6 font-bold text-center">Length/Angle</div>
          <div className="w-1/6 font-bold text-center">Metal</div>
          <div className="w-1/6 font-bold text-center">Weight</div>
          <div className="w-1/6 font-bold text-center">Liner</div>
          <div className="w-1/6 font-bold text-center">Price</div>
        </div>
      );
    } else {
      return <div>Your list is empty.</div>;
    }
  };

  return (
    <div className={`${canvasView ? "hidden" : ""} pt-5 flex flex-col`}>
      {tableHeading()}

      {filteredRunItemsArr.map((item) => {
        let fittings = null;
        if (item.type === "round") fittings = roundFittings;
        if (item.type === "rectangular") fittings = rectangularFittings;
        let measurement = [];
        for (let key in item.measurements) {
          measurement.push(
            <div key={Math.random()} className="flex flex-col self-auto">
              <span className="bg-gray-200 py-1 px-3 text-center font-bold">
                {item.measurements[key].label}
              </span>
              <span className="bg-gray-100 py-1 px-3 text-center">
                {item.measurements[key].value}
              </span>
            </div>
          );
        }

        return (
          <div
            key={item.id}
            className="flex flex-row justify-between border-b p-2"
          >
            <div className="w-1/12">
              {fittingQty(item.fittingId, item.measurements)}
            </div>
            <div className="w-2/5">{fittings[item.fittingId - 1].name}</div>
            <div className="w-2/5 flex flex-row flex-wrap gap-1 items-stretch">
              {measurement}
            </div>
            <div className="w-1/6 text-center">-</div>
            <div className="w-1/6 text-center">-</div>
            <div className="w-1/6 text-center">-</div>
            <div className="w-1/6 text-center">-</div>
            <div className="w-1/6 text-center">-</div>
          </div>
        );
      })}
    </div>
  );
};

export default ListView;

import squreThroatElbow from "../img/thumb/rectangular/square-throat-elbow.svg";
import f2 from "../img/thumb/rectangular/f2.svg";
import squareThroatAngle from "../img/thumb/rectangular/square-throat-angle.svg";
import rediusElbowWSquareThroat from "../img/thumb/rectangular/radius-elbow-w-square-throat.svg";
import radiusAngle from "../img/thumb/rectangular/radius-angle.svg";
import radiusElbowLeft from "../img/thumb/rectangular/radius-elbow-left.svg";
import radiusElbowRight from "../img/thumb/rectangular/radius-elbow-right.svg";
import bullheadTee from "../img/thumb/rectangular/bullhead-tee.svg";
import yBranch from "../img/thumb/rectangular/y-branch.svg";
import pants from "../img/thumb/rectangular/pants.svg";
import topSideFlatTransition from "../img/thumb/rectangular/top-and-sides-flat-transition.svg";
import reducingOffsetLeft from "../img/thumb/rectangular/reducing-offset-left.svg";
import reducingOffsetRight from "../img/thumb/rectangular/reducing-offset-right.svg";
import radiusReducingOffsetLeft from "../img/thumb/rectangular/radius-reducing-offset-left.svg";
import radiusReducingOffsetRight from "../img/thumb/rectangular/radius-reducing-offset-right.svg";
import concentricTransition from "../img/thumb/rectangular/concentric-transition.svg";
import ninetyDegreeIncreaseTap from "../img/thumb/rectangular/90-degree-increased-tap.svg";
import ninetyDegreeStraightTap from "../img/thumb/rectangular/90-degree-straight-tap.svg";
import topBottomFlatTransition from "../img/thumb/rectangular/top-or-bottom-flat-transition.svg";
import caps from "../img/thumb/rectangular/caps.svg";
import flexConnector from "../img/thumb/rectangular/flex-connector.svg";
import rectangularDoubleWall from "../img/thumb/rectangular/rectangular-double-wall.svg";
import d1 from "../img/thumb/rectangular/d1.svg";
import d2 from "../img/thumb/rectangular/d2.svg";
// For SVG fittings
import SVG_squreThroatElbow from "../img/svg/rectangular/square-throat-elbow.svg";
import SVG_f2 from "../img/svg/rectangular/f2.svg";
import SVG_squareThroatAngle from "../img/svg/rectangular/square-throat-angle.svg";
import SVG_rediusElbowWSquareThroat from "../img/svg/rectangular/radius-elbow-w-square-throat.svg";
import SVG_radiusAngle from "../img/svg/rectangular/radius-angle.svg";
import SVG_radiusElbowLeft from "../img/svg/rectangular/radius-elbow-left.svg";
import SVG_radiusElbowRight from "../img/svg/rectangular/radius-elbow-right.svg";
import SVG_bullheadTee from "../img/svg/rectangular/bullhead-tee.svg";
import SVG_yBranch from "../img/svg/rectangular/y-branch.svg";
import SVG_pants from "../img/svg/rectangular/pants.svg";
import SVG_topSideFlatTransition from "../img/svg/rectangular/top-and-sides-flat-transition.svg";
import SVG_reducingOffsetLeft from "../img/svg/rectangular/reducing-offset-left.svg";
import SVG_reducingOffsetRight from "../img/svg/rectangular/reducing-offset-right.svg";
import SVG_radiusReducingOffsetLeft from "../img/svg/rectangular/radius-reducing-offset-left.svg";
import SVG_radiusReducingOffsetRight from "../img/svg/rectangular/radius-reducing-offset-right.svg";
import SVG_concentricTransition from "../img/svg/rectangular/concentric-transition.svg";
import SVG_ninetyDegreeIncreaseTap from "../img/svg/rectangular/90-degree-increased-tap.svg";
import SVG_ninetyDegreeStraightTap from "../img/svg/rectangular/90-degree-straight-tap.svg";
import SVG_topBottomFlatTransition from "../img/svg/rectangular/top-or-bottom-flat-transition.svg";
import SVG_caps from "../img/svg/rectangular/caps.svg";
import SVG_flexConnector from "../img/svg/rectangular/flex-connector.svg";
import SVG_rectangularDoubleWall from "../img/svg/rectangular/rectangular-double-wall.svg";
import SVG_d1 from "../img/svg/rectangular/d1.svg";
import SVG_d2 from "../img/svg/rectangular/d2.svg";

const rectangularFittings = [
  {
    id: 1,
    name: "Square Throat Elbow",
    image: squreThroatElbow,
    svg: SVG_squreThroatElbow,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f1_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f1_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f1_3",
      },
      {
        label: "TV",
        type: "text",
        id: "f1_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f1_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f1_6",
      },
    ],
  },
  {
    id: 2,
    name: "F-2",
    image: f2,
    svg: SVG_f2,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f2_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f2_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f2_3",
      },
      {
        label: "TV",
        type: "text",
        id: "f2_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f2_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f2_6",
      },
      {
        label: "TV",
        type: "text",
        id: "f2_7",
      },
      {
        label: "M",
        type: "text",
        id: "f2_8",
      },
      {
        label: "O",
        type: "text",
        id: "f2_9",
      },
    ],
  },
  {
    id: 3,
    name: "Square Throat Angle",
    image: squareThroatAngle,
    svg: SVG_squareThroatAngle,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f3_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f3_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f3_3",
      },
      {
        label: "S1",
        type: "text",
        id: "f3_4",
      },
      {
        label: "S2",
        type: "text",
        id: "f3_5",
      },
      {
        label: "A",
        type: "text",
        id: "f3_6",
      },
    ],
  },
  {
    id: 4,
    name: "Radius Elbow W Square Throat",
    image: rediusElbowWSquareThroat,
    svg: SVG_rediusElbowWSquareThroat,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f4_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f4_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f4_3",
      },
      {
        label: "S1",
        type: "text",
        id: "f4_4",
      },
      {
        label: "S2",
        type: "text",
        id: "f4_5",
      },
      {
        label: "A",
        type: "text",
        id: "f4_6",
      },
    ],
  },
  {
    id: 5,
    name: "Radius Angle",
    image: radiusAngle,
    svg: SVG_radiusAngle,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f5_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f5_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f5_3",
      },
      {
        label: "S1",
        type: "text",
        id: "f5_4",
      },
      {
        label: "S2",
        type: "text",
        id: "f5_5",
      },
      {
        label: "A",
        type: "text",
        id: "f5_6",
      },
      {
        label: "R",
        type: "text",
        id: "f5_7",
      },
    ],
  },
  {
    id: 6,
    name: "Radius Elbow Left",
    image: radiusElbowLeft,
    svg: SVG_radiusElbowLeft,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f6_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f6_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f6_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f6_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f6_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f6_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f6_7",
      },
      {
        label: "R",
        type: "text",
        id: "f6_8",
      },
    ],
  },
  {
    id: 7,
    name: "Radius Elbow Right",
    image: radiusElbowRight,
    svg: SVG_radiusElbowRight,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f7_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f7_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f7_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f7_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f7_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f7_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f7_7",
      },
      {
        label: "R",
        type: "text",
        id: "f7_8",
      },
    ],
  },
  {
    id: 8,
    name: "Bullhead Tee",
    image: bullheadTee,
    svg: SVG_bullheadTee,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f8_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f8_2",
      },
      {
        label: "W3",
        type: "text",
        id: "f8_3",
      },
      {
        label: "S1",
        type: "text",
        id: "f8_4",
      },
      {
        label: "S2",
        type: "text",
        id: "f8_5",
      },
      {
        label: "S3",
        type: "text",
        id: "f8_6",
      },
      {
        label: "TV",
        type: "text",
        id: "f8_7",
      },
    ],
  },
  {
    id: 9,
    name: "Y Branch",
    image: yBranch,
    svg: SVG_yBranch,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f9_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f9_2",
      },
      {
        label: "W3",
        type: "text",
        id: "f9_3",
      },
      {
        label: "D1",
        type: "text",
        id: "f9_4",
      },
      {
        label: "D2",
        type: "text",
        id: "f9_5",
      },
      {
        label: "D3",
        type: "text",
        id: "f9_6",
      },
      {
        label: "S1",
        type: "text",
        id: "f9_7",
      },
      {
        label: "S2",
        type: "text",
        id: "f9_8",
      },
      {
        label: "S3",
        type: "text",
        id: "f9_9",
      },
      {
        label: "S4",
        type: "text",
        id: "f9_10",
      },
      {
        label: "TD1",
        type: "text",
        id: "f9_11",
      },
      {
        label: "TD2",
        type: "text",
        id: "f9_12",
      },
      {
        label: "R1",
        type: "text",
        id: "f9_13",
      },
      {
        label: "R2",
        type: "text",
        id: "f9_14",
      },
    ],
  },
  {
    id: 10,
    name: "Pants",
    image: pants,
    svg: SVG_pants,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f10_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f10_2",
      },
      {
        label: "W3",
        type: "text",
        id: "f10_3",
      },
      {
        label: "D1",
        type: "text",
        id: "f10_4",
      },
      {
        label: "D2",
        type: "text",
        id: "f10_5",
      },
      {
        label: "O1",
        type: "text",
        id: "f10_6",
      },
      {
        label: "O2",
        type: "text",
        id: "f10_7",
      },
      {
        label: "TD",
        type: "text",
        id: "f10_8",
      },
      {
        label: "T",
        type: "text",
        id: "f10_9",
      },
      {
        label: "L",
        type: "text",
        id: "f10_10",
      },
    ],
  },
  {
    id: 11,
    name: "Top and Side Flat Transition",
    image: topSideFlatTransition,
    svg: SVG_topSideFlatTransition,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f11_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f11_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f11_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f11_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f11_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f11_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f11_7",
      },
      {
        label: "L",
        type: "text",
        id: "f11_8",
      },
    ],
  },
  {
    id: 12,
    name: "Reducing Offset Left",
    image: reducingOffsetLeft,
    svg: SVG_reducingOffsetLeft,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f12_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f12_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f12_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f12_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f12_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f12_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f12_7",
      },
      {
        label: "L",
        type: "text",
        id: "f12_8",
      },
      {
        label: "O",
        type: "text",
        id: "f12_9",
      },
    ],
  },
  {
    id: 13,
    name: "Reducing Offset Right",
    image: reducingOffsetRight,
    svg: SVG_reducingOffsetRight,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f13_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f13_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f13_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f13_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f13_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f13_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f13_7",
      },
      {
        label: "L",
        type: "text",
        id: "f13_8",
      },
      {
        label: "O",
        type: "text",
        id: "f13_9",
      },
    ],
  },
  {
    id: 14,
    name: "Radius Reducing Offset Left",
    image: radiusReducingOffsetLeft,
    svg: SVG_radiusReducingOffsetLeft,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f14_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f14_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f14_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f14_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f14_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f14_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f14_7",
      },
      {
        label: "L",
        type: "text",
        id: "f14_8",
      },
      {
        label: "O",
        type: "text",
        id: "f14_9",
      },
    ],
  },
  {
    id: 15,
    name: "Radius Reducing Offset Right",
    image: radiusReducingOffsetRight,
    svg: SVG_radiusReducingOffsetRight,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f15_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f15_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f15_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f15_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f15_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f15_6",
      },
      {
        label: "TD",
        type: "text",
        id: "f15_7",
      },
      {
        label: "L",
        type: "text",
        id: "f15_8",
      },
      {
        label: "O",
        type: "text",
        id: "f15_9",
      },
    ],
  },
  {
    id: 16,
    name: "Concentric Transition",
    image: concentricTransition,
    svg: SVG_concentricTransition,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f16_1",
      },
      {
        label: "W2",
        type: "text",
        id: "f16_2",
      },
      {
        label: "D1",
        type: "text",
        id: "f16_3",
      },
      {
        label: "D2",
        type: "text",
        id: "f16_4",
      },
      {
        label: "S1",
        type: "text",
        id: "f16_5",
      },
      {
        label: "S2",
        type: "text",
        id: "f16_6",
      },
      {
        label: "L",
        type: "text",
        id: "f16_7",
      },
    ],
  },
  {
    id: 17,
    name: "90 Degree Increased Tap",
    image: ninetyDegreeIncreaseTap,
    svg: SVG_ninetyDegreeIncreaseTap,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f17_1",
      },
      {
        label: "D1",
        type: "text",
        id: "f17_2",
      },
      {
        label: "S1",
        type: "text",
        id: "f17_3",
      },
      {
        label: "A",
        type: "text",
        id: "f17_4",
      },
      {
        label: "L",
        type: "text",
        id: "f17_5",
      },
    ],
  },
  {
    id: 18,
    name: "90 Degree Straight Tap",
    image: ninetyDegreeStraightTap,
    svg: SVG_ninetyDegreeStraightTap,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f18_1",
      },
      {
        label: "D1",
        type: "text",
        id: "f18_2",
      },
      {
        label: "A",
        type: "text",
        id: "f18_4",
      },
      {
        label: "L",
        type: "text",
        id: "f18_5",
      },
    ],
  },
  {
    id: 19,
    name: "Top or Bottom Flat Transition",
    image: topBottomFlatTransition,
    svg: SVG_topBottomFlatTransition,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f19_1",
      },
      {
        label: "D1",
        type: "text",
        id: "f19_2",
      },
      {
        label: "d2",
        type: "text",
        id: "f19_4",
      },
      {
        label: "TD",
        type: "text",
        id: "f19_5",
      },
      {
        label: "O",
        type: "text",
        id: "f19_6",
      },
      {
        label: "L",
        type: "text",
        id: "f19_7",
      },
    ],
  },
  {
    id: 20,
    name: "Caps",
    image: caps,
    svg: SVG_caps,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f20_1",
      },
      {
        label: "D1",
        type: "text",
        id: "f20_2",
      },
    ],
  },
  {
    id: 21,
    name: "Flex Connector",
    image: flexConnector,
    svg: SVG_flexConnector,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "f21_1",
      },
      {
        label: "D1",
        type: "text",
        id: "f21_2",
      },
    ],
  },
  {
    id: 22,
    name: "Rectangular Double Wall",
    image: rectangularDoubleWall,
    svg: SVG_rectangularDoubleWall,
    image_top: "",
    fields: [
      {
        label: "A1",
        type: "text",
        id: "f22_1",
      },
      {
        label: "A2",
        type: "text",
        id: "f22_2",
      },
      {
        label: "D",
        type: "text",
        id: "f22_3",
      },
    ],
  },
  {
    id: 23,
    name: "D-1",
    image: d1,
    svg: SVG_d1,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "d1_1",
      },
      {
        label: "D1",
        type: "text",
        id: "d1_2",
      },
      {
        label: "L",
        type: "text",
        id: "d1_3",
      },
    ],
  },
  {
    id: 24,
    name: "D-2",
    image: d2,
    svg: SVG_d2,
    image_top: "",
    fields: [
      {
        label: "W1",
        type: "text",
        id: "d2_1",
      },
      {
        label: "D1",
        type: "text",
        id: "d2_2",
      },
      {
        label: "L",
        type: "text",
        id: "d2_3",
      },
    ],
  },
];

export default rectangularFittings;

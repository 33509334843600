import React from "react";

import useStore from "../Store/useStore";

import JobSiteDetails from "./JobSiteDetails";
import JobSiteRun from "./JobSiteRun";
import JobSites from "./JobSites";

const JobList = () => {
  const { jobs } = useStore((state) => ({
    jobs: state.jobSite,
  }));

  return (
    <>
      <div className="flex flex-row flex-wrap gap-2 mt-20">
        {jobs.length > 0 ? <JobSites /> : "Start by adding a Job Site."}
      </div>
      <div className="mt-5">
        <JobSiteDetails />
      </div>
      <div className="mt-5">
        <JobSiteRun />
      </div>
    </>
  );
};

export default JobList;

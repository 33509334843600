import React from "react";
import { nanoid } from "nanoid";
import RoundFittings from "../data/round";

import useStore from "../Store/useStore";

const RoundFitting = ({ jobIndex, runIndex }) => {
  const { addFitting } = useStore((state) => ({
    addFitting: state.addFitting,
  }));
  return (
    <>
      {RoundFittings.map((fitting) => {
        return (
          <div
            key={nanoid()}
            className="shadow p-2 cursor-pointer hover:bg-gray-100"
            onClick={() => addFitting(fitting.id, "round", jobIndex, runIndex)}
          >
            <img src={fitting.image} alt={fitting.name} width="90" />
          </div>
        );
      })}
    </>
  );
};

export default RoundFitting;

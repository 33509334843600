import React from "react";
import useStore from "../Store/useStore";
import Icons from "../Icons";
import RoundFitting from "./RoundFitting";
import RectangularFitting from "./RectangularFitting";
import Canvas from "../components/Canvas";
import ListView from "./ListView";

const JobSiteRun = () => {
  const { jobs, siteOpen, addRun, setRunOpen, setTypeOpen } = useStore(
    (state) => ({
      jobs: state.jobSite,
      addRun: state.addRun,
      siteOpen: state.jobSiteOpen,
      setRunOpen: state.setRunOpen,
      setTypeOpen: state.setTypeOpen,
    })
  );

  return (
    <>
      {jobs.map((job, jobIndex) => {
        return (
          <div
            key={job.id}
            data-job-id={job.id}
            className={`${siteOpen !== job.id ? "hidden" : ""}`}
          >
            <div className="uppercase font-bold flex flex-row gap-2 px-4 py-2 border-b-2 bg-gray-100">
              <button className="btn-success" onClick={() => addRun(jobIndex)}>
                {Icons.plus} Run
              </button>
            </div>

            <div className="bg-white p-4 flex flex-row flex-wrap gap-2">
              {job.runs.length === 0 && "Start by clicking add Run button."}
              {job.runs.map((run) => {
                return (
                  <div key={run.id}>
                    <button
                      className={`${
                        job.runOpen === run.id ? "btn-primary" : "btn-secondary"
                      }`}
                      onClick={() => setRunOpen(jobIndex, run.id)}
                    >
                      {run.name}
                    </button>
                  </div>
                );
              })}
            </div>
            <div className="bg-white px-4">
              {job.runs.map((run, runIndex) => {
                return (
                  <div
                    key={run.id}
                    className={`${job.runOpen !== run.id ? "hidden" : ""}`}
                  >
                    <div className="flex flex-row gap-2">
                      <button
                        className={`${
                          job.typeOpen === "round"
                            ? "btn-primary"
                            : "btn-secondary"
                        }`}
                        onClick={() => setTypeOpen(jobIndex, "round")}
                      >
                        Round
                      </button>
                      <button
                        className={`${
                          job.typeOpen === "rectangular"
                            ? "btn-primary"
                            : "btn-secondary"
                        }`}
                        onClick={() => setTypeOpen(jobIndex, "rectangular")}
                      >
                        Rectangular
                      </button>
                    </div>
                    <div
                      className={`${
                        job.typeOpen === "round" ? "" : "hidden"
                      } flex flex-wrap flex-row gap-2 mt-5`}
                    >
                      <RoundFitting jobIndex={jobIndex} runIndex={runIndex} />
                    </div>
                    <div
                      className={`${
                        job.typeOpen === "rectangular" ? "" : "hidden"
                      } flex flex-wrap flex-row gap-2 mt-5`}
                    >
                      <RectangularFitting
                        jobIndex={jobIndex}
                        runIndex={runIndex}
                      />
                    </div>
                    {/* Canvas View */}
                    <Canvas jobIndex={jobIndex} runIndex={runIndex} />
                    {/* List View */}
                    <ListView run={run} />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default JobSiteRun;

import spiral from "../img/thumb/round/spiral.svg";
import longitudinalSeem from "../img/thumb/round/longitudinal-seam.svg";
import squareEll2pc from "../img/thumb/round/square-ell-2-pc.svg";
import radiusEll90Degrees from "../img/thumb/round/radius-ell-90-degrees.svg";
import bullheadTee from "../img/thumb/round/bullhead-tee.svg";
import pantsWye from "../img/thumb/round/pants-wye.svg";
import offset from "../img/thumb/round/offset.svg";
import reducer from "../img/thumb/round/reducer.svg";
import taper from "../img/thumb/round/taper.svg";
import lowPressureWye from "../img/thumb/round/low-pressure-wye.svg";
import lateralWye from "../img/thumb/round/lateral-wye.svg";
import reducingLateralWye from "../img/thumb/round/reducing-lateral-wye.svg";
import ninetyDegreesTee from "../img/thumb/round/90-degrees-tee.svg";
import canonicalTee from "../img/thumb/round/conical-tee.svg";
import lowLossTee from "../img/thumb/round/low-loss-tee.svg";
import saddleTap from "../img/thumb/round/saddle-tap.svg";
import canonicalSaddleTap from "../img/thumb/round/conical-saddle-tap.svg";
import saddleTapFlat from "../img/thumb/round/saddle-tap-flat.svg";
import canonicalSaddleFlat from "../img/thumb/round/conical-saddle-flat.svg";
import squareToRound from "../img/thumb/round/square-to-round.svg";
import coupling from "../img/thumb/round/coupling.svg";
import endCaps from "../img/thumb/round/end-caps.svg";
import flexConnector from "../img/thumb/round/flex-connector.svg";
import hetod from "../img/thumb/round/hetod.svg";
import radiusEll225Degrees from "../img/thumb/round/radius-ell-22.5-degrees.svg";
import radiusEll30Degrees from "../img/thumb/round/radius-ell-30-degrees.svg";
import radiusEll45Degrees from "../img/thumb/round/radius-ell-45-degrees.svg";
import radiusEll60Degrees from "../img/thumb/round/radius-ell-60-degrees.svg";
import canonicalTakeOff from "../img/thumb/round/conical-takeoff.svg";
import pressOnCollarTakeOff from "../img/thumb/round/press-on-collar-takeoff.svg";
import conductorPipe from "../img/thumb/round/conductor-pipe.svg";
// For SVG fittings
import SVG_spiral from "../img/svg/round/spiral.svg";
import SVG_longitudinalSeem from "../img/svg/round/longitudinal-seam.svg";
import SVG_squareEll2pc from "../img/svg/round/square-ell-2-pc.svg";
import SVG_radiusEll90Degrees from "../img/svg/round/radius-ell-90-degrees.svg";
import SVG_bullheadTee from "../img/svg/round/bullhead-tee.svg";
import SVG_pantsWye from "../img/svg/round/pants-wye.svg";
import SVG_offset from "../img/svg/round/offset.svg";
import SVG_reducer from "../img/svg/round/reducer.svg";
import SVG_taper from "../img/svg/round/taper.svg";
import SVG_lowPressureWye from "../img/svg/round/low-pressure-wye.svg";
import SVG_lateralWye from "../img/svg/round/lateral-wye.svg";
import SVG_reducingLateralWye from "../img/svg/round/reducing-lateral-wye.svg";
import SVG_ninetyDegreesTee from "../img/svg/round/90-degrees-tee.svg";
import SVG_canonicalTee from "../img/svg/round/conical-tee.svg";
import SVG_lowLossTee from "../img/svg/round/low-loss-tee.svg";
import SVG_saddleTap from "../img/svg/round/saddle-tap.svg";
import SVG_canonicalSaddleTap from "../img/svg/round/conical-saddle-tap.svg";
import SVG_saddleTapFlat from "../img/svg/round/saddle-tap-flat.svg";
import SVG_canonicalSaddleFlat from "../img/svg/round/conical-saddle-flat.svg";
import SVG_squareToRound from "../img/svg/round/square-to-round.svg";
import SVG_coupling from "../img/svg/round/coupling.svg";
import SVG_endCaps from "../img/svg/round/end-caps.svg";
import SVG_flexConnector from "../img/svg/round/flex-connector.svg";
import SVG_hetod from "../img/svg/round/hetod.svg";
import SVG_radiusEll225Degrees from "../img/svg/round/radius-ell-22.5-degrees.svg";
import SVG_radiusEll30Degrees from "../img/svg/round/radius-ell-30-degrees.svg";
import SVG_radiusEll45Degrees from "../img/svg/round/radius-ell-45-degrees.svg";
import SVG_radiusEll60Degrees from "../img/svg/round/radius-ell-60-degrees.svg";
import SVG_canonicalTakeOff from "../img/svg/round/conical-takeoff.svg";
import SVG_pressOnCollarTakeOff from "../img/svg/round/press-on-collar-takeoff.svg";
import SVG_conductorPipe from "../img/svg/round/conductor-pipe.svg";

const diameterSymbol = "&#8960;";
const roundFittings = [
  {
    id: 1,
    name: "Spiral",
    image: spiral,
    svg: SVG_spiral,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r1_1",
      },
      {
        label: "L",
        type: "text",
        id: "r1_2",
      },
    ],
  },
  {
    id: 2,
    name: "Longitudinal Seam",
    image: longitudinalSeem,
    svg: SVG_longitudinalSeem,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r2_1",
      },
      {
        label: "L",
        type: "text",
        id: "r2_2",
      },
    ],
  },
  {
    id: 3,
    name: "Square Ell 2 - pc",
    image: squareEll2pc,
    svg: SVG_squareEll2pc,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r3_1",
      },
      {
        label: "TV",
        type: "text",
        id: "r3_2",
      },
      {
        label: "D1",
        type: "text",
        id: "r3_3",
      },
    ],
  },
  {
    id: 4,
    name: "Radius Ell 90 degree",
    image: radiusEll90Degrees,
    svg: SVG_radiusEll90Degrees,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r4_1",
      },
      {
        label: "D1",
        type: "text",
        id: "r4_2",
      },
      {
        label: "A",
        type: "text",
        id: "r4_3",
      },
      {
        label: "R",
        type: "text",
        id: "r4_4",
      },
    ],
  },
  {
    id: 5,
    name: "Bullhead Tee",
    image: bullheadTee,
    svg: SVG_bullheadTee,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r5_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r5_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r5_3",
      },
      {
        label: "TV",
        type: "text",
        id: "r5_4",
      },
    ],
  },
  {
    id: 6,
    name: "Pants - Wye",
    image: pantsWye,
    svg: SVG_pantsWye,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r6_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r6_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r6_3",
      },
    ],
  },
  {
    id: 7,
    name: "Offset",
    image: offset,
    svg: SVG_offset,
    image_top: "",
    fields: [
      {
        label: "L",
        type: "text",
        id: "r7_l",
      },
      {
        label: "D1",
        type: "text",
        id: "r7_2",
      },
    ],
  },
  {
    id: 8,
    name: "Reducer",
    image: reducer,
    svg: SVG_reducer,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r8_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r8_2",
      },
      {
        label: "L",
        type: "text",
        id: "r8_3",
      },
    ],
  },
  {
    id: 9,
    name: "Taper",
    image: taper,
    svg: SVG_taper,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r9_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r9_2",
      },
      {
        label: "L",
        type: "text",
        id: "r9_3",
      },
    ],
  },
  {
    id: 10,
    name: "Low Pressure Wye",
    image: lowPressureWye,
    svg: SVG_lowPressureWye,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r10_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r10_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r10_3",
      },
    ],
  },
  {
    id: 11,
    name: "Lateral Wye",
    image: lateralWye,
    svg: SVG_lateralWye,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r11_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r11_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r11_3",
      },
    ],
  },
  {
    id: 12,
    name: "Reducing Lateral Wye",
    image: reducingLateralWye,
    svg: SVG_reducingLateralWye,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r12_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r12_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r12_3",
      },
    ],
  },
  {
    id: 13,
    name: "90 Degree Tee",
    image: ninetyDegreesTee,
    svg: SVG_ninetyDegreesTee,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r13_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r13_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r13_3",
      },
    ],
  },
  {
    id: 14,
    name: "Conical Tee",
    image: canonicalTee,
    svg: SVG_canonicalTee,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r14_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r14_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r14_3",
      },
    ],
  },
  {
    id: 15,
    name: "Low Loss Tee",
    image: lowLossTee,
    svg: SVG_lowLossTee,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r15_1",
      },
      {
        label: "D2",
        type: "text",
        id: "r15_2",
      },
      {
        label: "D3",
        type: "text",
        id: "r15_3",
      },
    ],
  },
  {
    id: 16,
    name: "Saddle Tap",
    image: saddleTap,
    svg: SVG_saddleTap,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r16_1",
      },
      {
        label: diameterSymbol,
        type: "text",
        id: "r16_2",
      },
    ],
  },
  {
    id: 17,
    name: "Conical Saddle Tap",
    image: canonicalSaddleTap,
    svg: SVG_canonicalSaddleTap,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r17_1",
      },
      {
        label: diameterSymbol,
        type: "text",
        id: "r17_2",
      },
    ],
  },
  {
    id: 18,
    name: "Saddle Tap Flat",
    image: saddleTapFlat,
    svg: SVG_saddleTapFlat,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r18_1",
      },
    ],
  },
  {
    id: 19,
    name: "Conical Saddle Flat",
    image: canonicalSaddleFlat,
    svg: SVG_canonicalSaddleFlat,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r19_1",
      },
    ],
  },
  {
    id: 20,
    name: "Square to Round",
    image: squareToRound,
    svg: SVG_squareToRound,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r20_1",
      },
      {
        label: "TD",
        type: "text",
        id: "r20_2",
      },
      {
        label: "L",
        type: "text",
        id: "r20_3",
      },
      {
        label: "W1",
        type: "text",
        id: "r20_4",
      },
      {
        label: "D1",
        type: "text",
        id: "r20_5",
      },
    ],
  },
  {
    id: 21,
    name: "Coupling",
    image: coupling,
    svg: SVG_coupling,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r21_1",
      },
    ],
  },
  {
    id: 22,
    name: "End Caps",
    image: endCaps,
    svg: SVG_endCaps,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r22_1",
      },
    ],
  },
  {
    id: 23,
    name: "Flex Connector",
    image: flexConnector,
    svg: SVG_flexConnector,
    image_top: "",
    fields: [
      {
        label: "D1",
        type: "text",
        id: "r23_1",
      },
    ],
  },
  {
    id: 24,
    name: "Hetod",
    image: hetod,
    svg: SVG_hetod,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r24_1",
      },
      {
        label: "B",
        type: "text",
        id: "r24_2",
      },
      {
        label: "D",
        type: "text",
        id: "r24_3",
      },
    ],
  },
  {
    id: 25,
    name: "Radius Ell 22.5 Degrees",
    image: radiusEll225Degrees,
    svg: SVG_radiusEll225Degrees,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r25_1",
      },
      {
        label: "D1",
        type: "text",
        id: "r25_2",
      },
      {
        label: "D2",
        type: "text",
        id: "r25_3",
      },
      {
        label: "R",
        type: "text",
        id: "r25_4",
      },
    ],
  },
  {
    id: 26,
    name: "Radius Ell 30 Degrees",
    image: radiusEll30Degrees,
    svg: SVG_radiusEll30Degrees,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r26_1",
      },
      {
        label: "D1",
        type: "text",
        id: "r26_2",
      },
      {
        label: "D2",
        type: "text",
        id: "r26_3",
      },
      {
        label: "R",
        type: "text",
        id: "r26_4",
      },
    ],
  },
  {
    id: 27,
    name: "Radius Ell 45 Degrees",
    image: radiusEll45Degrees,
    svg: SVG_radiusEll45Degrees,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r27_1",
      },
      {
        label: "D1",
        type: "text",
        id: "r27_2",
      },
      {
        label: "D2",
        type: "text",
        id: "r27_3",
      },
      {
        label: "R",
        type: "text",
        id: "r27_4",
      },
    ],
  },
  {
    id: 28,
    name: "Radius Ell 60 Degrees",
    image: radiusEll60Degrees,
    svg: SVG_radiusEll60Degrees,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r28_1",
      },
      {
        label: "D1",
        type: "text",
        id: "r28_2",
      },
      {
        label: "D2",
        type: "text",
        id: "r28_3",
      },
      {
        label: "R",
        type: "text",
        id: "r28_4",
      },
    ],
  },
  {
    id: 29,
    name: "Conical Takeoff",
    image: canonicalTakeOff,
    svg: SVG_canonicalTakeOff,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r29_1",
      },
      {
        label: "B",
        type: "text",
        id: "r29_2",
      },
      {
        label: "C",
        type: "text",
        id: "r29_3",
      },
      {
        label: "D",
        type: "text",
        id: "r29_4",
      },
    ],
  },
  {
    id: 30,
    name: "Press on Collar Takeoff",
    image: pressOnCollarTakeOff,
    svg: SVG_pressOnCollarTakeOff,
    image_top: "",
    fields: [
      {
        label: "A",
        type: "text",
        id: "r30_1",
      },
      {
        label: "B",
        type: "text",
        id: "r30_2",
      },
      {
        label: "C",
        type: "text",
        id: "r30_3",
      },
      {
        label: "D",
        type: "text",
        id: "r30_4",
      },
    ],
  },
  {
    id: 31,
    name: "Conductor Pipe",
    image: conductorPipe,
    svg: SVG_conductorPipe,
    image_top: "",
    fields: [],
  },
];

export default roundFittings;

import React, { useState, useEffect } from "react";
import _ from "lodash";
import useStore from "../Store/useStore";
import Icons from "../Icons";

const JobSiteDetails = () => {
  const { jobs, siteOpen, setJobName, setSiteDetails } = useStore((state) => ({
    jobs: state.jobSite,
    siteOpen: state.jobSiteOpen,
    setJobName: state.setJobName,
    setSiteDetails: state.setSiteDetails,
  }));
  const [openSection, setOpenSection] = useState(false);
  const [loading, setLoading] = useState(false);

  // Save Site Details
  const saveDetails = (jobId, index) => {
    setLoading(true);
    console.log(`saving ${jobId} ....`);

    let siteDetails = {};

    const container = document.querySelector(`[data-job-id="${jobId}"]`);
    const labels = container.querySelectorAll("[data-label]");
    const checkboxes = container.querySelectorAll(
      "[data-detail] input[type=checkbox]"
    );
    const radios = container.querySelectorAll(
      "[data-detail] input[type=radio]"
    );
    const texts = container.querySelectorAll("[data-detail] input[type=text]");
    const jobName = container.querySelector("#job-name");

    if (jobName.value.trim() !== "") {
      setJobName(index, jobName.value);
    }

    labels.forEach(function (label) {
      siteDetails[label.textContent] = [];
    });

    checkboxes.forEach(function (chk) {
      const label = chk
        .closest("[data-detail]")
        .querySelector("label").textContent;
      const chkLabel = chk.closest("label").querySelector("span").textContent;
      const chkId = chk.id;

      if (chk.checked) {
        siteDetails[label].push({
          id: chkId,
          type: "checkbox",
          label: chkLabel,
        });
      }
    });

    radios.forEach(function (rd) {
      const label = rd
        .closest("[data-detail]")
        .querySelector("label").textContent;
      const radioLabel = rd.closest("label").querySelector("span").textContent;

      if (rd.checked) {
        siteDetails[label].push({
          id: rd.id,
          type: "radio",
          label: radioLabel,
        });
      }
    });

    texts.forEach(function (txt) {
      const label = txt
        .closest("[data-detail]")
        .querySelector("label").textContent;

      if (txt.value !== "") {
        siteDetails[label].push({
          id: txt.id,
          type: "text",
          value: txt.value,
        });
      }
    });

    setSiteDetails(index, siteDetails);

    _.delay(function () {
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    jobs.forEach(function (job) {
      if (Object.keys(job.siteDetails).length > 0) {
        for (let key in job.siteDetails) {
          job.siteDetails[key].forEach(function (detail) {
            if (detail.type === "checkbox") {
              document.querySelector(`[id="${detail.id}"]`).checked = true;
            }
            if (detail.type === "radio") {
              document.querySelector(`[id="${detail.id}"]`).checked = true;
            }
            if (detail.type === "text") {
              document.querySelector(`[id="${detail.id}"]`).value =
                detail.value;
            }
          });
        }
      }
    });
  }, [jobs]);

  return (
    <>
      {jobs.map((job, index) => {
        return (
          <div
            key={job.id}
            data-job-id={job.id}
            className={`${siteOpen !== job.id ? "hidden" : ""}`}
          >
            <div
              className="uppercase font-bold py-4 px-5 bg-gray-100 border-b-2 cursor-pointer"
              onClick={() => setOpenSection(!openSection)}
            >
              {openSection ? Icons.arrowUp : Icons.arrowDown} Site Details
            </div>
            <div
              className={`flex flex-col py-4 px-5 bg-gray-50 ${
                !openSection ? "hidden" : "shadow-lg"
              }`}
            >
              <div className="pb-5 mb-5 border-b">
                <label className="font-bold mr-2">Job Name</label>
                <input
                  type="text"
                  className="form-input rounded"
                  id="job-name"
                  defaultValue={job.name}
                />
              </div>
              <div className="flex flex-row justify-between mb-5 gap-5">
                <div data-detail className="w-1/4">
                  <label data-label>Code</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`smacna-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>SMACNA</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`umc-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>UMC</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`other-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Others</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Duct</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`assemble-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Assemble</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`kd-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>KD</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`pitts-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Pitts</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`snaplock-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Snap Lock</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Stiffening</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`bead-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Bead</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`crossbreak-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Cross Break</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>End Connetors</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`tdc-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>TDC</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`ductmate-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Duct Mate</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`sd-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>S &amp; D</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between mb-5 gap-5">
                <div data-detail className="w-1/4">
                  <label data-label>Cold sealant: In seams</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="radio"
                        name="csis"
                        value="yes"
                        id={`csis-yes-${job.id}`}
                        className="form-radio"
                      />
                      <span>Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="csis"
                        value="no"
                        id={`csis-no-${job.id}`}
                        className="form-radio"
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Pressure Class</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`np-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Negative or Positive</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`1/2-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>1/2"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`2-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>2"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`4-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>4"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`10-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>10"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`1-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>1"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`3-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>3"</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`6-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>6"</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Fittings</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="checkbox"
                        id={`fitt-assemble-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Assembled</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`fitt-prefab-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>PreFab</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`fitt-pitts-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Pitts</span>
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        id={`fitt-snaplock-${job.id}`}
                        className="form-checkbox"
                      />
                      <span>Snap Lock</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Exposed: 1 label per piece</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="radio"
                        name="elpp"
                        value="yes"
                        id={`elpp-yes-${job.id}`}
                        className="form-radio"
                      />
                      <span>Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="elpp"
                        value="no"
                        id={`elpp-no-${job.id}`}
                        className="form-radio"
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex flex-row justify-between mb-5 gap-5">
                <div data-detail className="w-1/4 flex flex-col">
                  <label data-label>Material Type</label>
                  <input
                    type="text"
                    id={`material-type-${job.id}`}
                    className="form-input rounded"
                  />
                </div>
                <div data-detail className="w-1/4 flex flex-col">
                  <label data-label>Exterior Angle Iron</label>
                  <input
                    type="text"
                    id={`exterior-angle-iron-${job.id}`}
                    className="form-input rounded"
                  />
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Roof Top Duct</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="radio"
                        name="rtd"
                        value="yes"
                        id={`rtd-yes-${job.id}`}
                        className="form-radio"
                      />
                      <span>Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="rtd"
                        value="no"
                        id={`rtd-no-${job.id}`}
                        className="form-radio"
                      />
                      <span>No</span>
                    </label>
                  </div>
                </div>
                <div data-detail className="w-1/4">
                  <label data-label>Installed</label>
                  <div className="flex flex-row flex-wrap gap-4">
                    <label>
                      <input
                        type="radio"
                        name="installed"
                        className="form-radio"
                        id={`installed-yes-${job.id}`}
                        value="yes"
                      />
                      <span>Yes</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="installed"
                        id={`installed-no-${job.id}`}
                        className="form-radio"
                        value="no"
                      />
                      <span>No</span>
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="installed"
                        className="form-radio"
                        id={`installed-none-${job.id}`}
                        value="none"
                      />
                      <span>None</span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex gap-2">
                <button
                  className="btn-success"
                  onClick={() => saveDetails(job.id, index)}
                >
                  {loading ? "Saving..." : "Save"}
                </button>
                <button className="btn-white">Clear All</button>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default JobSiteDetails;

import React, { useEffect, useState } from "react";
import { fabric } from "fabric";
import useStore from "../Store/useStore";
import rectangularFittings from "../data/rectangular";
import roundFittings from "../data/round";
import _ from "lodash";
import { Dialog } from "@headlessui/react";
import Popup from "./Popup";

const Canvas = ({ jobIndex, runIndex }) => {
  const { jobSite, setCanvas, setMeasurements, canvasView } = useStore(
    (state) => ({
      jobSite: state.jobSite,
      setCanvas: state.setCanvas,
      canvasView: state.canvasView,
      setMeasurements: state.setMeasurements,
    })
  );

  const runItems = jobSite[jobIndex].runs[runIndex].items;
  const canvasData = jobSite[jobIndex].runs[runIndex].canvas;
  const canvasId = `canvas-${jobSite[jobIndex].runs[runIndex].id}`;
  const [canvasElement, setCanvasElement] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [fittingInfo, setFittingInfo] = useState({});
  const [fittingName, setFittingName] = useState("");

  const getFittingIdsInCanvas = () => {
    let fittingInCanvas = [];
    if (canvasData.objects) {
      canvasData.objects.map((obj) => fittingInCanvas.push(obj.id));
    }
    return fittingInCanvas;
  };

  const loadCanvasFromJSON = (canvas) => {
    console.log("loadCanvasFromJSON");
    canvas.loadFromJSON(
      canvasData,
      function () {
        canvas.renderAll();
      },
      function (o, object) {
        runItems.forEach(function (item) {
          if (item.id === o.id) {
            const hasMeasurements = Object.keys(item.measurements).length > 0;

            if (object._objects && object._objects.length > 0) {
              object._objects.forEach(function (line) {
                if (hasMeasurements) {
                  line.set("stroke", "rgb(34,153,84)");
                  line.set("fill", "rgb(162,216,156)");
                } else {
                  line.set("stroke", "rgb(0,0,0)");
                  line.set("fill", "rgb(255,255,255)");
                }
              });
            } else {
              // Single line
              if (hasMeasurements) {
                object.set("stroke", "rgb(34,153,84)");
              } else {
                object.set("stroke", "rgb(0,0,0)");
              }
            }
          }
        });
      }
    );
  };

  const addFittingInCanvas = (canvas) => {
    console.log("addFittingInCanvas");
    runItems.forEach((item, index) => {
      let fittings = null;
      switch (item.type) {
        case "round":
          fittings = roundFittings;
          break;

        case "rectangular":
          fittings = rectangularFittings;
          break;
        default:
          break;
      }

      if (fittings) {
        if (!getFittingIdsInCanvas().includes(item.id)) {
          fabric.loadSVGFromURL(
            fittings[item.fittingId - 1].svg,
            function (objects, options) {
              console.log("loadSVGFromURL");
              const fitting = fabric.util.groupSVGElements(objects, options);
              fitting.scaleToWidth(100);
              fitting.scaleToHeight(150);
              fitting["fittingId"] = item.fittingId;
              fitting["fittingType"] = item.type;
              fitting["itemIndex"] = index;
              fitting["id"] = item.id;
              canvas.add(fitting);

              canvas.renderAll();
            }
          );
        }
      }
    });
  };

  const saveCanvas = (canvas) => {
    canvas.on("object:modified", function () {
      console.time("Updating Canvas");

      setCanvas((state) => {
        state.jobSite[jobIndex].runs[runIndex].canvas = canvas.toJSON([
          "fittingId",
          "fittingType",
          "itemIndex",
          "id",
        ]);
      });

      console.timeEnd("Updating Canvas");
    });
  };

  const recalculateFieldValues = (itemIndex) => {
    if (jobSite[jobIndex].runs[runIndex].items[itemIndex]) {
      const measurements =
        jobSite[jobIndex].runs[runIndex].items[itemIndex].measurements;

      if (measurements && Object.keys(measurements).length > 0) {
        for (let key in measurements) {
          document.querySelector(`#${key}`).value = measurements[key].value;
        }
      }
    }
  };

  const openMeasurements = (canvas) => {
    canvas.on("mouse:dblclick", function (evt) {
      const selectedObject = evt.target;

      if (selectedObject?.fittingId) {
        const fittingId = selectedObject.fittingId;
        const itemIndex = selectedObject.itemIndex;
        const fittingType = selectedObject.fittingType;
        setFittingInfo({ itemIndex, fittingId, fittingType });

        if (fittingType === "round") {
          setFittingName(roundFittings[fittingId - 1].name);
        } else if (fittingType === "rectangular") {
          setFittingName(rectangularFittings[fittingId - 1].name);
        }

        setIsOpen(true);

        recalculateFieldValues(itemIndex);
      }
    });
  };

  const measurementFields = () => {
    if (Object.keys(fittingInfo).length > 0) {
      let fittings = null;
      if (fittingInfo.fittingType === "round") {
        fittings = roundFittings;
      } else if (fittingInfo.fittingType === "rectangular") {
        fittings = rectangularFittings;
      }

      return fittings[fittingInfo.fittingId - 1].fields;
    }
  };

  const saveMeasurements = () => {
    const formFields = document.querySelectorAll(
      `#measurement-fields input[type=text]`
    );
    let fields = {};
    formFields.forEach(function (field) {
      const label = field.closest("div").querySelector("label").textContent;
      if (field.value !== "") {
        fields[field.id] = { label: label, value: field.value };
      }
    });

    setMeasurements(jobIndex, runIndex, fittingInfo.itemIndex, fields);

    setIsOpen(false);
    console.log("Saving measurements");
  };

  useEffect(() => {
    if (!canvasElement) {
      // TODO: initialize canvas when window get resized.
      const canvas = new fabric.Canvas(canvasId, {
        width: document.body.clientWidth - 64,
        height: 1000,
      });

      canvas.setBackgroundColor(null, canvas.renderAll.bind(canvas));

      console.time("DocLoad: loadCanvasFromJSON");
      loadCanvasFromJSON(canvas);
      console.timeEnd("DocLoad: loadCanvasFromJSON");
      setCanvasElement(canvas);

      openMeasurements(canvas);
      saveCanvas(canvas);
    }

    if (canvasElement) {
      canvasElement.setBackgroundColor(
        null,
        canvasElement.renderAll.bind(canvasElement)
      );

      if (canvasData) {
        console.time("loadCanvasFromJSON");
        loadCanvasFromJSON(canvasElement);
        console.timeEnd("loadCanvasFromJSON");
      }
      _.debounce(function () {
        saveCanvas(canvasElement);
      }, 500);
      openMeasurements(canvasElement);

      addFittingInCanvas(canvasElement);
    }
  }, [runItems]);

  return (
    <div className={`${!canvasView ? "hidden" : ""} mt-5`}>
      <canvas id={canvasId}></canvas>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <Dialog.Title className="text-2xl mb-5">{fittingName}</Dialog.Title>

        <div id="measurement-fields" className="flex flex-row flex-wrap gap-2">
          {measurementFields() &&
            measurementFields().map((field) => {
              return (
                <div key={field.id} className="flex flex-col">
                  <label className="mr-2 text-center font-bold">
                    {field.label}
                  </label>
                  <input
                    type="text"
                    className="form-input w-24 rounded text-center"
                    id={field.id}
                  />
                </div>
              );
            })}
        </div>

        <div className="flex flex-row gap-2 mt-5">
          <button className="btn-success" onClick={() => saveMeasurements()}>
            Save
          </button>
          <button className="btn-secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </button>
        </div>
      </Popup>
    </div>
  );
};

export default Canvas;

import React from "react";
import { Dialog } from "@headlessui/react";

const Popup = ({ isOpen, setIsOpen, children }) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="fixed z-10 inset-0 overflow-y-auto"
    >
      <div className="flex items-center justify-center min-h-screen">
        <Dialog.Overlay className="fixed z-5 inset-0 bg-black opacity-30" />
        <div className="bg-white rounded min-w-1/2 max-w-3xl mx-auto fixed z-11 p-5">
          {children}
        </div>
      </div>
    </Dialog>
  );
};

export default Popup;
